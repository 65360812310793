import {useLocation} from '@remix-run/react';
import {Button} from '#/app/components/Button.jsx';
import {Image} from '#/app/components/Image.jsx';
import {TalkToUsButton} from '#/app/components/TalkToUsButton.jsx';
import {type Feature} from '#/app/content.js';
import {getQuoteImage} from '#/app/helpers/getQuoteImage.js';

type FeatureProps = {
  feature: Feature;
};

export function Feature(props: FeatureProps) {
  const {feature} = props;

  const location = useLocation();
  const isIndex = location.pathname === '/';

  return (
    <div className="flex flex-col-reverse items-center justify-between py-8 sm:flex-row">
      <div className="">
        <div className="mb-4 flex size-10 items-center justify-center">
          <feature.icon color={feature.color} />
        </div>
        <div className={`text-${feature.color}-500 mb-10 text-lg font-semibold uppercase`}>{feature.name}</div>
        <div className="mb-12 space-y-8">
          <h2 className="whitespace-pre-wrap">{feature.title}</h2>
          <p className="ml-0.5 whitespace-pre-wrap text-xl leading-relaxed text-gray-500 sm:text-2xl">{feature.description}</p>
          <div className="flex space-x-4">
            <Button
              appearance="primary"
              className="_cta"
              color={feature.color}
              href="/register"
              size="lg"
            >
              {feature.ctaText || 'Try Ufleet for Free'}
            </Button>
            {isIndex ? (
              <Button
                appearance="outline"
                className="_cta"
                color={feature.color}
                href={feature.link}
                size="lg"
              >
                Learn More
              </Button>
            ) : (
              <TalkToUsButton color={feature.color} />
            )}
          </div>
        </div>
        {feature.quote && (
          <div className="space-y-4">
            <div className="text-lgx whitespace-pre-wrap text-gray-500">“{feature.quote.quote}”</div>
            <div className="flex items-center space-x-4">
              <Image
                alt={feature.quote.person}
                className="size-14 rounded-full"
                layout=""
                src={getQuoteImage(feature.quote)}
              />
              <div className="">
                <div className="text-md font-medium text-gray-500">{feature.quote.person}</div>
                <div className="text-md italic text-gray-400">
                  {feature.quote.title}
                  ,
                  <span className="ffont-medium">{feature.quote.company}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mx-auto flex w-10/12 shrink-0 items-center justify-center sm:w-7/12 sm:pl-24">
        <Image alt={`${feature.name}: ${feature.title}`} src={feature.image} />
      </div>
    </div>
  );
}
